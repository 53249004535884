import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: `'Billo', 'Roboto', sans-serif`,
        body: `'Billo', 'Roboto', sans-serif`,
    },
    styles: {
        global: {
            body: {
                bg: "#D9419A",
                color: "#FFFFFF"
            }
        }
    }
})

export default theme
