import React, {useMemo} from 'react';
import {
    ChakraProvider,
    Box,
    Flex,
    Spacer,
    ButtonGroup,
    Heading,
    Text,
    Link
} from "@chakra-ui/react"
import theme from './theme'
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { SlotMachine } from "./SlotMachine";

global.Buffer = require('buffer').Buffer;

require('@solana/wallet-adapter-react-ui/styles.css');
require('./App.css');

export const App = () => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            // new BackpackWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ChakraProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                        <Box width={"100%"} textAlign="center" fontSize="xl" padding={10}>
                            <Flex minWidth='max-content' alignItems='center' gap='2' marginBottom={20}>
                                <Heading size='lg' fontFamily={"Billo"}>KAWAII spin</Heading>
                                <Spacer />
                                <ButtonGroup gap='2'>
                                    <WalletMultiButton />
                                </ButtonGroup>
                            </Flex>
                            <Text>
                                NYAA~! (´∇｀) welcomy to our KAWAII crypto wonderland! (^ω^) CLICKY on the magic button, SPEND a teeny-tiny amount of SOL, and VOILA~! (｡♥‿♥｡) you'll receive a SURPRISE-filled package of a WANDOM wainbow-hued COIN~! OWO get ready for a crypto ADVENCHA like no othew! (灬º‿º灬)♡
                            </Text>
                            <SlotMachine />
                            <Flex width={"50%"} alignItems={"center"} gap='5' marginTop={10}>
                                <Link href={"https://twitter.com/KawaiiSpinGames"}>TWITTER</Link>
                                <Link href={"https://t.me/KawaiiSpins"}>TELEGRAM</Link>
                            </Flex>
                        </Box>
                    </WalletModalProvider>
                </WalletProvider>
            </ConnectionProvider>
        </ChakraProvider>
    )
}
