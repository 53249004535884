export interface Coin {
    name: string;
    image: string;
    address: string;
    feeAccount: string;
}

const coins: Coin[] = [
    // {
    //     name: "Kawaii",
    //     image: "https://kawaiispin.games/apple-icon.png",
    //     address: "ErD5gRzNZBL2kZPPeNHh1FhLAqoWUdGKxVqJ9rkxN1pD",
    //     feeAccount: "",
    // },
    {
        name: "AllBridge",
        image: "https://assets.coingecko.com/coins/images/18690/large/abr.png",
        address: "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
        feeAccount: "DCxrH9BQXZaqxxhAxLdC4osEXhcyQULcqwSyZVs5wJRj",
    },
    {
        name: "Aurory",
        image: "https://assets.coingecko.com/coins/images/19324/large/Ico_Blanc.png",
        address: "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
        feeAccount: "4ibVYi5wPjm1rmxA4dhR6rQdPWoJdTki7TgQ4utcTXzh"
    },
    {
        name: "Bonfida",
        image: "https://assets.coingecko.com/coins/images/13395/large/bonfida.png",
        address: "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
        feeAccount: "5koiyb2yTjWPbjMjbeSW5T4RQjmJFMqe5vNJxBLhT5Nx"
    },
    {
        name: "Bonk",
        image: "https://assets.coingecko.com/coins/images/28600/large/bonk.jpg",
        address: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
        feeAccount: "GPKpAYBtAjPBiZADLwUwMF722RHUcLXTph3FfQ6PJwnp"
    },
    {
        name: "Chad",
        image: "https://assets.coingecko.com/coins/images/32738/large/chad.png",
        address: "G7rwEgk8KgQ4RUTnMy2W2i7dRDq4hXHD4CSp9PSmSbRW",
        feeAccount: "FW6ezkzweauF2kVJR5pzBsewFdCWN1zg8q6JZkwYid7Z"
    },
    {
        name: "Dark Protocol",
        image: "https://assets.coingecko.com/coins/images/32959/large/Dark-Logo.png",
        address: "FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS",
        feeAccount: "3ejnqnaXFejAUoqy89fMp1cXSYgnSqMatTUUdmkNTt4i"
    },
    {
        name: "Dexlab",
        image: "https://assets.coingecko.com/coins/images/17276/large/3_GradientSymbol.png",
        address: "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
        feeAccount: "3zqt9ZXKL1gBee1PRCV4PKhHzxbjZmLmYUKCmTA8WB8J"
    },
    {
        name: "Dust Protocol",
        image: "https://assets.coingecko.com/coins/images/24289/large/6388d49d-1f00-448d-92bc-f2db1441bbce.",
        address: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
        feeAccount: "F8RRrGkpVz8eXdBBX3BktVBQrLXoFzpDMMJdxgYh3U7M"
    },
    {
        name: "Shadow Token",
        image: "https://assets.coingecko.com/coins/images/22271/large/Property_1_Color.png",
        address: "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
        feeAccount: "k6PmcMzchgMoVreSS6apgdxtdXw4xYbDbhCVemamjfD"
    },
    {
        name: "Genopets",
        image: "https://assets.coingecko.com/coins/images/20360/large/gene-token.png",
        address: "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
        feeAccount: "28unN9QBRezVEY3RJ7VVRth1ygaoUFdwsJGjAE7RwyiK"
    },
    {
        name: "Guacamole",
        image: "https://assets.coingecko.com/coins/images/30119/large/GUAC_Official_Logo.png",
        address: "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
        feeAccount: "3Y5fEK4TNdekbEySTK7GE751o9DrdBUoZ7JYiRUuBJ3C"
    },
    {
        name: "Helium",
        image: "https://assets.coingecko.com/coins/images/4284/large/Helium_HNT.png",
        address: "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
        feeAccount: "HshMtVEyvhSVtmFLAd56zRw53ubNLMntSEfvKMnHdDfh"
    },
    {
        name: "Hivemapper",
        image: "https://assets.coingecko.com/coins/images/28388/large/honey.png",
        address: "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy",
        feeAccount: "12WwUtJDucwGfdXqj4d8oGqKq3sfrNGyXJd235iFenp"
    },
    {
        name: "Hubble",
        image: "https://assets.coingecko.com/coins/images/22070/large/hubble.PNG",
        address: "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
        feeAccount: "9tJr8VJzy1FP89PNGrGWebMt1Y4caYeBoVbbAcQZgoqc"
    },
    {
        name: "HXRO",
        image: "https://assets.coingecko.com/coins/images/7805/large/X_3D_Black_(10).png",
        address: "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
        feeAccount: "3qn613jnbvmPQRZUE1x81vYU4YWxAANPaBYgMxkjqBNB"
    },
    {
        name: "Jito",
        image: "https://assets.coingecko.com/coins/images/33228/large/jto.png",
        address: "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
        feeAccount: "9o3feenMLv7LeFbKGo1Z9cNbbg2DBte5XKmtbuRXkwPg"
    },
    {
        name: "King",
        image: "https://assets.coingecko.com/coins/images/30183/large/king.png",
        address: "9noXzpXnkyEcKF3AeXqUHTdR59V5uvrRBUZ9bwfQwxeq",
        feeAccount: "2DBadgRnUXEnHRgLMWwfw8XJjboULb8E3oXhPFkd4uUG"
    },
    {
        name: "Mango",
        image: "https://assets.coingecko.com/coins/images/14773/large/token-mango.png",
        address: "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
        feeAccount: "2yLo9rNuReygB4QJgLgs5WAYEfTHwdTyd1427k9TTnA4"
    },
    {
        name: "Marinade",
        image: "https://assets.coingecko.com/coins/images/18867/large/MNDE.png",
        address: "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
        feeAccount: "2hKbvfeSbadhqZRunicTQ4Sp8sM3aFcmy7Ym7XagKsA5"
    },
    {
        name: "Myro",
        image: "https://assets.coingecko.com/coins/images/32878/large/MYRO_200x200.png",
        address: "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4",
        feeAccount: "5hquhKqyx3J7ixszuZEfhQMVHb1W7JbVKFMbK8eXxwkW"
    },
    {
        name: "Nosana",
        image: "https://assets.coingecko.com/coins/images/22553/large/POfb_I4u_400x400.jpg",
        address: "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
        feeAccount: "78heQnyYtCiV2mKubwebktAFdncPWzSgaXvdzfhGVJJt"
    },
    {
        name: "Only Possible On Solana",
        image: "https://assets.coingecko.com/coins/images/32151/large/8e94bc23-f4c1-4c11-9c23-d063953a0d03.png",
        address: "BqVHWpwUDgMik5gbTciFfozadpE2oZth5bxCDrgbDt52",
        feeAccount: "AGfxnJA3uwBbofTVrXfJhayygfNjgny64P6xruF62G5v"
    },
    {
        name: "Orca",
        image: "https://assets.coingecko.com/coins/images/17547/large/Orca_Logo.png",
        address: "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
        feeAccount: "39E7JAAU22o1q6ScF4TGENd1wnu93XDaDVM2yWm9bbDE"
    },
    {
        name: "Peepo",
        image: "https://assets.coingecko.com/coins/images/32849/large/channels4_profile.jpg",
        address: "n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik",
        feeAccount: "BVVtCLu8z34apo9zADyReDwn42yuYmDCCABnuFRG9fg"
    },
    {
        name: "Pyth Network",
        image: "https://assets.coingecko.com/coins/images/31924/large/pyth.png",
        address: "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
        feeAccount: "C22YFrLbM5nghC2SV6gogNzJeXsNPxm1MQEiR9AUvX5m"
    },
    {
        name: "Raydium",
        image: "https://assets.coingecko.com/coins/images/13928/large/PSigc4ie_400x400.jpg",
        address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
        feeAccount: "CR5cUyNKP24oG47h2DYRsCr9H7SKECm1CH82XqEs9Zta"
    },
    {
        name: "Samoyedcoin",
        image: "https://assets.coingecko.com/coins/images/15051/large/IXeEj5e.png",
        address: "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
        feeAccount: "5JoaRvcNsGjfVye51PLfbxZqVHnMFqhA42wjWQaRXsvt"
    },
    {
        name: "Sdrive",
        image: "https://assets.coingecko.com/coins/images/32851/large/scoin_200px_transparent.png",
        address: "5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW",
        feeAccount: "2Y6wgKqWuTUKW1EkxUvmtGt3JVSnFVZBF9yELGweQyCb"
    },
    {
        name: "SolBlaze",
        image: "https://assets.coingecko.com/coins/images/28392/large/blze.png",
        address: "BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA",
        feeAccount: "5sEfoAP5HK53UNYxT9xsDNfUvcraTs51T8fxo5YzeajN"
    },
    {
        name: "Solcasino",
        image: "https://assets.coingecko.com/coins/images/30228/large/logo_(2).png",
        address: "SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz",
        feeAccount: "9pxWjJ7W1d8JUgqcLaZasaSVn3CJ2zpSygzm438EeJuQ"
    },
    {
        name: "Solcial",
        image: "https://assets.coingecko.com/coins/images/24583/large/1_N9szP0_400x400.jpg",
        address: "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV",
        feeAccount: "CkotTiNM8EE9knKdS57s5wgy1tkHK1LVmCufFdd7HhY3"
    },
    {
        name: "Solend",
        image: "https://assets.coingecko.com/coins/images/19573/large/i6AMOwun_400x400.jpg",
        address: "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
        feeAccount: "AJDMzXzVBj3HRUia9QkwQQHQEj8jCuYiWFmEwdt7REvQ"
    },
    {
        name: "Star Atlas DAO",
        image: "https://assets.coingecko.com/coins/images/17789/large/POLIS.jpg",
        address: "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
        feeAccount: "9deRQ8NRHjLKtf6HmLJUaAf1u45pexncW2zj9RU4cuY3"
    },
    {
        name: "StepN",
        image: "https://assets.coingecko.com/coins/images/23597/large/gmt.png",
        address: "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
        feeAccount: "DtVBbZMTyqZ8u9XtTpxQuqn3rSjjkmei7uQ1AMJaY3D3"
    },
    {
        name: "Synesis One",
        image: "https://assets.coingecko.com/coins/images/23289/large/sns.png",
        address: "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
        feeAccount: "qZwGMRz3TreMw6tJD7SR9a3Pqrmxwe8R4E4NG76yJKQ"
    },
    {
        name: "Sypool",
        image: "https://assets.coingecko.com/coins/images/18518/large/sypool.PNG",
        address: "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ",
        feeAccount: "FqZHPJYNaiSS1ToUyvxPiMUk6LZULu3XcUootxWNgepS"
    },
    {
        name: "UXD Protocol",
        image: "https://assets.coingecko.com/coins/images/20319/large/UXP.jpg",
        address: "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
        feeAccount: "7rsBAdXL3GDFwH34hPhC878Sb2hQ8FpLQXQNmxzN9aXF"
    },
    {
        name: "Walken",
        image: "https://assets.coingecko.com/coins/images/25566/large/wlkn.jpg",
        address: "EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az",
        feeAccount: "zNa4JXwnDpvay1w9jkWKgB6TcA5J52HCZ8hggyk1JNi"
    },
    {
        name: "SolanaPrime",
        image: "https://assets.coingecko.com/coins/images/25911/large/solana_black200x200.png",
        address: "PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2",
        feeAccount: "618kqoYWghrMfscdWkWYLv6DRQezqLLjSpWn9ywFEmL8"
    },
    {
        name: "Decimated",
        image: "https://assets.coingecko.com/coins/images/8271/large/dio_logo_coloured_transparent.png",
        address: "BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD",
        feeAccount: "2HARxxt8MfQTSt3Sxin1F8PfX89CZT3UdKm94Tw5FSHR"
    },
    {
        name: "StarLaunch",
        image: "https://assets.coingecko.com/coins/images/20109/large/OP3eksDQ_400x400.png",
        address: "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
        feeAccount: "GUM8T4YC9d9TbPMTAnR5Qy5RsjQm5ph3JYMALyTQUx3B"
    },
    {
        name: "Lamas Finance",
        image: "https://assets.coingecko.com/coins/images/32831/large/token-LMF.png",
        address: "LMFzmYL6y1FX8HsEmZ6yNKNzercBmtmpg2ZoLwuUboU",
        feeAccount: "3Ut6dno5nF2tvgWL7KfMVwVuYigvDgyw6BPzuG61NDR9"
    },
    {
        name: "Shredded Apes Whey",
        image: "https://assets.coingecko.com/coins/images/23256/large/whey-coin-2048x2048.png",
        address: "Ue4yjkPjA4QGis37eWbBsnqfzyK83BtY4AioDETp3Ab",
        feeAccount: "DwbjM6YZwRHv5J63QzRnnphSuZKbxCeNwdKm23Drdhc9"
    },
    {
        name: "Coin98",
        image: "https://assets.coingecko.com/coins/images/17117/large/logo.png",
        address: "C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9",
        feeAccount: "DP81XywVFXEQaha5aQXXAnpz1rReZXMCgM4aKmZbYVST"
    },
    {
        name: "Star Atlas",
        image: "https://assets.coingecko.com/coins/images/17659/large/Icon_Reverse.png",
        address: "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
        feeAccount: "TpQuZE6C4CtAFLxJbjwmkJNpwqQrBjVhG6CSUB1tpgh"
    },
    {
        name: "Access Protocol",
        image: "https://assets.coingecko.com/coins/images/28747/large/dR4FovX4_400x400.jpg",
        address: "5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y",
        feeAccount: "67cqTpyCXQ2kGfuQssBRTcG4vagU2aDSd6w2Y4uNTPuJ"
    },
    {
        name: "Kin",
        image: "https://assets.coingecko.com/coins/images/959/large/Kin_Logo.png",
        address: "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
        feeAccount: "79pqiugncbrn5gnnYpNhcnkvhwbcf1gX6oQbcEcGytF6"
    },
    {
        name: "Zebec Protocol",
        image: "https://assets.coingecko.com/coins/images/24342/large/zebec.jpeg",
        address: "zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF",
        feeAccount: "2Qd6nAtN95D6bfJ8fMmKe3xtj1FLFpS8Kb7sVoLcxbdL"
    },
    {
        name: "Step Finance",
        image: "https://assets.coingecko.com/coins/images/14988/large/step.png",
        address: "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
        feeAccount: "8MbocEWS1Jda1HJuNCjLpu217vaihrwuSojq1UFfttUN"
    },
    {
        name: "Kineko",
        image: "https://assets.coingecko.com/coins/images/26597/large/knk-cmc-logo.png",
        address: "kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz",
        feeAccount: "7eJPZQLPJ6ro5gtG5GeLV7EH5TECEXdXe2pm9zgWepPi"
    },
]

export function allImages() {
    return coins.map((coin) => coin.image);
}

export function randomCoin() {
    const randomIndex = Math.floor(Math.random() * coins.length)
    return coins[randomIndex]
}

export function randomCoinImage() {
    const randomIndex = Math.floor(Math.random() * coins.length)
    return coins[randomIndex]["image"]
}

export function chad() {
    return coins[2]
}

export function kawaii() {
    return coins[0]
}
