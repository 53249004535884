import React, {useEffect, useState, useMemo} from 'react';
import {
    Heading,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    VStack,
    useToast,
    Box, Image, HStack
} from "@chakra-ui/react"

import styles from './slots.module.css';
import {useWallet} from "@solana/wallet-adapter-react";
import {
    Connection,
    LAMPORTS_PER_SOL,
    VersionedTransaction,
} from "@solana/web3.js";
import {randomCoin, kawaii, Coin} from "./coins";

export const Slot = ({ url }: { url: string }) => {
    return (
        <Box border={"2px solid white"}
             borderRadius={"10px"}
             margin={{ base: "4px", sm: "6px", md: "8px", lg: "12px" }}
             padding={{ base: "4px", sm: "6px", md: "8px", lg: "10px" }}
             width={{ base: "40px", sm: "80px", md: "120px", lg: "160px" }}
             height={{ base: "40px", sm: "80px", md: "120px", lg: "160px" }}>
            <img src={url} alt={"slot"}/>
        </Box>
    );
};

export const SlotMachine = () => {
    const [displayImages, setDisplayImages] = useState([randomCoin().image, randomCoin().image, randomCoin().image])
    const [isSpinning, setIsSpinning] = useState(false);
    const [balance, setBalance] = useState(0)
    const [sliderValue, setSliderValue] = useState(50)
    const [sliderText, setSliderText] = useState("pick youw BET with the adorable wittle SLIDER, uwu~! 🌟💸")
    const [winningToken, setWinningToken] = useState<Coin | null>(null)
    // eslint-disable-next-line
    const [winningAmount, setWinningAmount] = useState(null)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined)

    const SLIDER_ADAPTER = 0

    const labelStyles = {
        mt: '2',
        ml: '-2.5',
        fontSize: 'sm',
    }

    const toast = useToast()
    const connection = useMemo(() => {
        return new Connection("https://mainnet.helius-rpc.com/?api-key=b8e95bf4-90e5-426e-ae39-30b11659ee6f");
    }, []);
    const { publicKey, sendTransaction } = useWallet();

    useEffect(() => {
        const fetchBalance = async () => {
            if (publicKey != null) {
                try {
                    let balance = await connection.getBalance(publicKey);
                    setBalance(balance / LAMPORTS_PER_SOL);
                } catch (error) {
                    console.error("Error fetching balance:", error);
                }
            } else {
                console.log("not connected");
            }
        };

        fetchBalance();
    }, [publicKey, connection]);

    useEffect(() => {
        if (isSpinning) {
            const id = setInterval(() => {
                setDisplayImages([randomCoin().image, randomCoin().image, randomCoin().image]);
            }, 100);
            setIntervalId(id)
        } else {
            if (intervalId !== undefined) {
                clearInterval(intervalId); // Clear the interval when isSpinning is false
            }

            if (winningToken != null) {
                setDisplayImages([winningToken.image, winningToken.image, winningToken.image]);
            } else {
                setDisplayImages([randomCoin().image, randomCoin().image, randomCoin().image]);
            }
        }

        return () => clearInterval(intervalId); // Cleanup on component unmount or isSpinning change
    },
        // eslint-disable-next-line
        [isSpinning, winningToken]
    );

    const handleSliderChange = (value: number) => {
        setSliderValue(value)

        if (sliderValue < 40) {
            setSliderText("ZERO degen energy with you, hehe~! 🌟💖")
        } else if (sliderValue >= 40 && sliderValue < 70) {
            setSliderText("no high stakes, just NORMAL vibes with you, uwu~! 😊💸")
        } else {
            setSliderText("high roller in the house, DEGEN energy at max, hehe~! 💎🎰")
        }

        if (publicKey != null && (sliderValue + SLIDER_ADAPTER) / 100 > balance) {
            setSliderText(`OOPSIE! NYOT ENOUGH cute coins for that bet, sowwy~! 💸💔\nyew onwy have ${balance} SOL`)
        }
    }

    const onPlay = async () => {
        let realSliderValue = sliderValue

        if (realSliderValue === 0) {
            realSliderValue = 1
        }

        const totalAmount = LAMPORTS_PER_SOL * ((realSliderValue + SLIDER_ADAPTER) / 100)
        const taxAmountBps = 250
        const finalToken = randomCoin()

        if (publicKey != null) {
            // 0. Start spinning
            setIsSpinning(true)
            setWinningToken(null)
            setWinningAmount(null)
            toast.closeAll()

            // 1. Query Jupiter with a random token
            toast({
                title: 'transaction PENDING uwu~! 💖',
                status: 'loading',
                duration: 10000,
                isClosable: true,
            })

            // Legacy
            //const quoteUrl = `https://quote-api.jup.ag/v6/quote?inputMint=So11111111111111111111111111111111111111112&outputMint=${finalToken.address}&amount=${totalAmount}&slippageBps=50&asLegacyTransaction=true&platformFeeBps=${taxAmountBps}`

            // Versioned Tx
            const quoteUrl = `https://quote-api.jup.ag/v6/quote?inputMint=So11111111111111111111111111111111111111112&outputMint=${finalToken.address}&amount=${totalAmount}&slippageBps=50&asLegacyTransaction=true&platformFeeBps=${taxAmountBps}`
            console.log("quoteURL:", quoteUrl)

            const quoteResponse = await (
                await fetch(quoteUrl)
            ).json();

            // get serialized transactions for the swap
            const {swapTransaction} = await (
                await fetch('https://quote-api.jup.ag/v6/swap', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        // quoteResponse from /quote api
                        quoteResponse,
                        // user public key to be used for the swap
                        userPublicKey: publicKey.toString(),
                        // auto wrap and unwrap SOL. default is true
                        wrapAndUnwrapSol: true,
                        // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
                        feeAccount: finalToken.feeAccount,
                        // asLegacyTransaction because why not (Ledger stuff that helps me w/ web3.js)
                        // asLegacyTransaction: true
                    })
                })
            ).json();

            const swapTransactionBuf = Buffer.from(swapTransaction, 'base64');

            // Legacy Tx
            // const swapTx = Transaction.from(swapTransactionBuf)

            // Versioned Tx
            const swapTxV = VersionedTransaction.deserialize(swapTransactionBuf);

            // Execute the transaction
            return sendTransaction(swapTxV, connection).then((sig) => {
                toast.closeAll()
                toast({
                    title: 'SUCCESS uwu~! 💖',
                    description: `Huwway! UwU~! 🎉💖`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setWinningToken(finalToken)
                setWinningAmount(quoteResponse["outAmount"])
                setIsSpinning(false)
            }).catch((error) => {
                console.log("error:", error)

                toast.closeAll()
                setIsSpinning(false)


                if (error !== "WalletSendTransactionError: User rejected the request." && error !== "WalletSendTransactionError: Approval Denied") {
                    toast({
                        title: 'sowwy 💖',
                        description: `something went wrong: ${error.toString().toUpperCase()} 😿💔`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }
            })
        }
    }

    const onDump = async () => {
        toast({
            title: 'soon 💖',
            description: `KAWAII token is on its way, pwease be a wittle more patient, UWU~`,
            status: 'info',
            duration: 5000,
            isClosable: true,
        })
        // const taxAmountBps = 250
        // const finalToken = kawaii()
        //
        // if (publicKey != null && winningToken != null && winningAmount != null) {
        //     // 0. Start spinning
        //     setIsSpinning(true)
        //     setWinningToken(null)
        //     toast.closeAll()
        //
        //     // 1. Query Jupiter with a random token
        //     toast({
        //         title: 'transaction PENDING uwu~! 💖',
        //         status: 'loading',
        //         duration: 10000,
        //         isClosable: true,
        //     })
        //
        //     // Legacy
        //     //const quoteUrl = `https://quote-api.jup.ag/v6/quote?inputMint=So11111111111111111111111111111111111111112&outputMint=${finalToken.address}&amount=${totalAmount}&slippageBps=50&asLegacyTransaction=true&platformFeeBps=${taxAmountBps}`
        //
        //     // Versioned Tx
        //     const quoteUrl = `https://quote-api.jup.ag/v6/quote?inputMint=${winningToken.address}&outputMint=${finalToken.address}&amount=${winningAmount}&slippageBps=50&asLegacyTransaction=true&platformFeeBps=${taxAmountBps}`
        //     console.log("quoteURL:", quoteUrl)
        //
        //     const quoteResponse = await (
        //         await fetch(quoteUrl)
        //     ).json();
        //
        //     // get serialized transactions for the swap
        //     const {swapTransaction} = await (
        //         await fetch('https://quote-api.jup.ag/v6/swap', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 // quoteResponse from /quote api
        //                 quoteResponse,
        //                 // user public key to be used for the swap
        //                 userPublicKey: publicKey.toString(),
        //                 // auto wrap and unwrap SOL. default is true
        //                 wrapAndUnwrapSol: true,
        //                 // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
        //                 feeAccount: finalToken.feeAccount,
        //                 // asLegacyTransaction because why not (Ledger stuff that helps me w/ web3.js)
        //                 // asLegacyTransaction: true
        //             })
        //         })
        //     ).json();
        //
        //     const swapTransactionBuf = Buffer.from(swapTransaction, 'base64');
        //
        //     // Legacy Tx
        //     // const swapTx = Transaction.from(swapTransactionBuf)
        //
        //     // Versioned Tx
        //     const swapTxV = VersionedTransaction.deserialize(swapTransactionBuf);
        //
        //     // Execute the transaction
        //     return sendTransaction(swapTxV, connection).then((sig) => {
        //         toast.closeAll()
        //         toast({
        //             title: 'SUCCESS uwu~! 💖',
        //             description: `Huwway! UwU~! 🎉💖`,
        //             status: 'success',
        //             duration: 5000,
        //             isClosable: true,
        //         })
        //
        //         setWinningToken(finalToken)
        //         setWinningAmount(null)
        //         setIsSpinning(false)
        //     }).catch((error) => {
        //         console.log("error:", error)
        //
        //         toast.closeAll()
        //         setIsSpinning(false)
        //
        //         if (error !== "WalletSendTransactionError: User rejected the request." && error !== "WalletSendTransactionError: Approval Denied") {
        //             toast({
        //                 title: 'sowwy 💖',
        //                 description: `something went wrong: ${error.toString().toUpperCase()} 😿💔`,
        //                 status: 'error',
        //                 duration: 5000,
        //                 isClosable: true,
        //             })
        //         }
        //     })
        // }
    }

    return (
        <>
        <div className={styles.slotMachineContainer}>
            {/*<VStack direction={"column"} spacing={10} width={"50%"}>*/}
            {/*    */}
            {/*</VStack>*/}

            <Box className={styles.slotMachineSubContainer} marginTop={20} width={"80%"} display={"flex"} alignItems={"center"}>
                <VStack paddingTop={5} spacing={10} width={"80%"}>
                    {/*<Heading>~ slot machine ~</Heading>*/}
                    <Heading>PWAY HEWE ~ uwu</Heading>
                    <Slider aria-label='slider-ex-6' onChange={(val) => handleSliderChange(val)}>
                        <SliderMark value={0} {...labelStyles}>
                            0 SOL
                        </SliderMark>
                        <SliderMark value={50} {...labelStyles}>
                            0.5 SOL
                        </SliderMark>
                        <SliderMark value={100} {...labelStyles}>
                            1 SOL
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Text>{sliderText}</Text>
                    <HStack spacing={0}>
                        <Slot url={displayImages[0]} key={0} />
                        <Slot url={displayImages[1]} key={1} />
                        <Slot url={displayImages[2]} key={2} />

                        <Button className={styles.lever}
                                width={{ base: "40px", sm: "60px", md: "60px", lg: "80px" }}
                                height={{ base: "40px", sm: "80px", md: "120px", lg: "160px" }}
                                isDisabled={publicKey == null || (sliderValue + SLIDER_ADAPTER) / 100 > balance}
                                isLoading={isSpinning}
                                onClick={() => {onPlay() }}>
                            START
                        </Button>
                    </HStack>
                </VStack>
            </Box>

            { winningToken != null ? (
                <>
                    { winningToken === kawaii() ? (
                        <div>
                            <br />
                            <Alert
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                height='200px'
                            >
                                <AlertIcon boxSize='40px' mr={0} />
                                <AlertTitle mt={4} mb={1} fontSize='lg'>
                                    UWU-tstandingly incwedible, like, TOTAWWY! 💖🌈
                                </AlertTitle>
                                <AlertDescription maxWidth='sm'>
                                    here's some - {winningToken["name"].toUpperCase()} - the best of the besties, uwu! 💕💫
                                </AlertDescription>
                            </Alert>
                            <br/>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <Alert
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                height='200px'
                            >
                                <AlertIcon boxSize='40px' mr={0} />
                                <AlertTitle mt={4} mb={1} fontSize='lg'>
                                    UWU-tstandingly incwedible, like, TOTAWWY! 💖🌈
                                </AlertTitle>
                                <AlertDescription maxWidth='sm'>
                                    here's some - {winningToken["name"].toUpperCase()} - just fow yu, enjoy the cuteness, uwu! 💕💫
                                </AlertDescription>
                            </Alert>
                            <br/>
                            <Button colorScheme='white' variant={"outline"} size='lg' onClick={() => {onDump() }}>
                                i want a REAL coin, this one is BAD
                            </Button>
                        </div>
                    )}
                    </>
            ) : (
                <div>
                    <br/>
                    <Box boxSize='sm'>
                        <Image src='./anime1.png' alt='casino weeb' />
                    </Box>
                </div>
            )}
        </div>

        </>
    );
};
